import 'dom4';

import '@neonaut/lib-js/es/dom/functionality/focus-outline-class';

import 'focus-visible/dist/focus-visible';

try {
	require('./vendor/sp-deps'); // Sitepark deps
	require('./vendor/sp-head'); // Sitepark head
} catch (error) {
	console.error('Sitepark JS error', error);
}
